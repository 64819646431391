import { Text, Flex, Heading, Grid, Box, useBreakpointValue } from '@chakra-ui/react'

import { CurvedStroke, CurvedStrokeVertical } from '../icons'

const Milestone = ({ text1, text2, left = '' }: { text1: string; text2: string; left?: string }) => {
  return (
    <Box
      w="160px"
      h="160px"
      textAlign="left"
      border="5px solid"
      borderColor="brand"
      position="relative"
      left={left}
      bgColor="white"
      zIndex="1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p={2}
    >
      <Text fontWeight="bold" fontSize="32" color="brown">
        {text1}
      </Text>
      <Text fontWeight="bold" fontSize="14" color="brown">
        {text2}
      </Text>
    </Box>
  )
}

const Milestones = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'lg' })
  return isMobile ? (
    <Flex position="relative">
      <Grid templateColumns="repeat(1, 4fr)" gap={10} alignItems="center" justifyContent="center">
        <Milestone text1="-20%" text2="d’émissions de gaz à effet de serre d’ici 2025" left="-40px" />
        <Milestone text1="-50%" text2="d’ici 2030" left="30px" />
        <Milestone text1="Objectif ZÉRO" text2="émissions nettes d’ici 2050" left="-5px" />
        <Box
          w="160px"
          h="160px"
          textAlign="center"
          borderColor="brand"
          position="relative"
          left="20px"
          bgColor="light_brown"
          zIndex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={4}
        >
          <Text fontWeight="bold" fontSize="38" color="white">
            {' '}
            NET
            <br />
            ZÉRO{' '}
          </Text>
          <Text fontWeight="bold" fontSize="14" color="white">
            {' '}
            d’ici 2050{' '}
          </Text>
        </Box>
      </Grid>
      <Box position="absolute" top="0" left="0" w="full" h="calc(100% - 160px)" zIndex="0">
        <CurvedStrokeVertical w="full" h="full" color="brand_text" />
      </Box>
    </Flex>
  ) : (
    <Flex position="relative">
      <Grid templateColumns="repeat(4, 1fr)" gap={10} alignItems="center" justifyContent="center">
        <Milestone text1="-20%" text2="d’émissions de gaz à effet de serre d’ici 2025" />
        <Milestone text1="-50%" text2="d’ici 2030" />
        <Milestone text1="Objectif ZÉRO" text2="émissions nettes d’ici 2050" />
        <Box
          w="160px"
          h="160px"
          textAlign="center"
          borderColor="brand"
          position="relative"
          bgColor="light_brown"
          zIndex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={4}
        >
          <Text fontWeight="bold" fontSize="38" color="white">
            {' '}
            NET
            <br />
            ZÉRO{' '}
          </Text>
          <Text fontWeight="bold" fontSize="14" color="white">
            {' '}
            d’ici 2050{' '}
          </Text>
        </Box>
      </Grid>
      <Box
        position="absolute"
        top="50%"
        left="160px"
        w="calc(100% - 320px)"
        h="full"
        zIndex="0"
        transform="translateY(-50%)"
      >
        <CurvedStroke w="full" h="full" color="brand_text" />
      </Box>
    </Flex>
  )
}

export const LesAmbitionsClimat = () => {
  return (
    <Flex
      direction="column"
      w="full"
      px={{ base: 2, md: 16 }}
      alignItems={'center'}
      mb={{ base: 12, md: 20 }}
      textAlign="center"
    >
      <Heading textAlign="center" w="full" size="md" mb={12}>
        Les ambitions Climat
      </Heading>

      <Text mb={12}>
        Le Plan Lait s’inscrit dans les engagements de Nestlé pour réduire son empreinte carbone <br />
        (engagements validés par le groupe d’expert SBTI pour limiter le réchauffement climatique à 1,5°C)
      </Text>
      <Milestones />
    </Flex>
  )
}
