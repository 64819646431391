import { Flex, Heading, Image, Text, UnorderedList, ListItem, Box } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { MicroMetha, SolsVivants, Agroforesterie, BienEtreAnimal } from '../icons'
import React from 'react'

export const RicoreSengage = () => {
  return (
    <Flex direction="column" w="full" px={{ base: 0, md: 16 }} alignItems={'center'} mb={{ base: 12, md: 20 }}>
      <Box as="span" id="engagements" visibility="hidden" height="0" transform="translateY(-80px)" />
      <Heading mb={12}>
        RICORÉ<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup> s’engage !
      </Heading>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} columnGap={8} px={4} mb={8}>
        <Flex position="relative" w={{ base: 'full', md: '50%' }} boxShadow="dark-lg" mb={8}>
          <Image src={s3ImageURL('ricore-vache.jpeg')} alt={''} objectFit="cover" w="100%" p={{ base: 2, md: 4 }} />
        </Flex>
        <Flex
          w={{ base: 'full', md: '50%' }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          m={{ base: 2, md: 6 }}
        >
          <Heading size="md" textAlign="left" w="100%" mb={8}>
            {' '}
            Le plan lait chez Nestlé
          </Heading>
          <Text mb={4}>
            En France, l’agriculture représente 21% des émissions de gaz à effet de serre nationales dont 6% liés à
            l’élevage laitier (Citepa 2022). En partenariat avec l’Institut de l’Elevage (Idele), Nestlé déploie la
            démarche de la filière laitière « Ferme Laitière Bas Carbone » pour accompagner les éleveurs laitiers dans
            la réduction de leurs émissions de gaz à effet de serre (GES) à partir d’un diagnostic individualisé et un
            plan d’action.
          </Text>
          <Text mb={8}>
            En parallèle, les éleveurs ont accès à un accompagnement technique et financier pour mettre en place des
            pratiques plus durables (réduction de l’empreinte carbone, bien-être animal, biodiversité) et à un réseau de
            fermes pilotes testant des solutions innovantes.
          </Text>
          <Flex w="full" direction="row" columnGap={{ base: 2, md: 8 }}>
            <MicroMetha boxSize={{ base: 16, md: 24 }} />
            <Agroforesterie boxSize={{ base: 16, md: 24 }} />
            <SolsVivants boxSize={{ base: 16, md: 24 }} />
            <BienEtreAnimal boxSize={{ base: 16, md: 24 }} />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w="full"
        direction={{ base: 'column', md: 'row' }}
        px={8}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Flex w={{ base: 'full', md: '60%' }} direction="column" justifyContent="center" alignItems="center">
          <Heading size="md" textAlign="left" w="100%" mb={8}>
            Les engagements RICORÉ<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup>
          </Heading>
          <Flex direction="row" alignItems="center" mb={4}>
            <Text
              as="span"
              fontSize="40px"
              color="light_brown"
              mr={4}
              bg="linear-gradient(0deg, #FFDC00 50%, white 50%)"
              fontWeight="bold"
              lineHeight="1"
              display="flex"
              alignItems="center"
            >
              100%
            </Text>
            <Text display="flex" alignItems="center" h="full">
              du lait utilisé dans notre recette RICORÉ® au Lait provient de la région Grand Est.
            </Text>
          </Flex>

          <Flex direction="column" mb={4}>
            <Text>Le parcours d’un éleveur engagé avec Nestlé pendant 5 ans est le suivant :</Text>
            <UnorderedList>
              <ListItem>
                Année 1
                <UnorderedList>
                  <ListItem>Réalisation d’un diagnostic individualisé</ListItem>
                  <ListItem>Identification des leviers d’action et réalisation d’un plan d’action</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                Année 3 réalisation d’une visite à mi-parcours pour suivre la mise en place des leviers d’action
              </ListItem>
              <ListItem>
                Année 5 réalisation d’un second diagnostic pour mesurer la baisse effective des émissions
              </ListItem>
            </UnorderedList>
          </Flex>

          <Text>
            Des sessions de restitution des résultats en groupe et des formations techniques collectives sont également
            prévues. Pour l’usine de Challerange produisant Ricoré au lait, cela concerne une centaine d’éleveurs.
          </Text>
        </Flex>
        <Flex justifyContent="center" position="relative" alignItems="flex-end" mt={8}>
          <Image src={s3ImageURL('ricore-lait-cafe-chicoree.png')} alt={''} maxH="400px" objectFit="cover" zIndex={1} />
          <Image src={s3ImageURL('sunrise.svg')} position="absolute" bottom="0" />
        </Flex>
      </Flex>
    </Flex>
  )
}
