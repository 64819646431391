import { Flex, Button, Image } from '@chakra-ui/react'

import { Icon, Link } from '@miimosa/design-system'
import { s3ImageURL } from '@miimosa/common'

export const Footer = () => {
  return (
    <Flex direction="column" w="full" bg="brand_orange" py={8} alignItems={'center'}>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} rowGap={10}>
        <Flex w="full" direction="column" rowGap={4} justifyContent="center" alignItems="center" color="brand_text">
          <Link href="https://www.miimosa.com" isExternal>
            <Icon name={'Logo2'} size="2xl" h="auto" />
          </Link>
          <Flex direction="row" columnGap={2}>
            <Link isExternal href="https://www.instagram.com/MiiMOSA_fr/">
              <Icon name="Instagram" size="4xs" h="auto" />
            </Link>
            <Link isExternal href="https://www.facebook.com/MiiMOSAfr">
              <Icon name="Facebook" size="4xs" />
            </Link>
            <Link isExternal href="https://www.linkedin.com/company/miimosa/">
              <Icon name="LinkedIn" size="4xs" h="auto" />
            </Link>
          </Flex>
          <Link href="https://blog.miimosa.com/" isExternal>
            <Button variant="brandPrimary">Blog MiiMOSA</Button>
          </Link>
        </Flex>
        <Flex w="full" direction="column" rowGap={6} justifyContent="center" alignItems="center">
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Image
              src={s3ImageURL('ricore-logo.png')}
              width="150"
              height="150"
              alt={''}
              position="relative"
              bottom="20%"
              left="2%"
            />
            <Flex direction="row" columnGap={2} color="brand_text" position="relative" bottom="30%">
              <Link href="https://x.com/NestleFr" isExternal>
                <Icon name="X" size="4xs" h="auto" />
              </Link>
              <Link isExternal href="https://www.instagram.com/nestleenfrance/">
                <Icon name="Instagram" size="4xs" h="auto" />
              </Link>
              <Link isExternal href="https://fr-fr.facebook.com/Nestle.fr/">
                <Icon name="Facebook" size="4xs" m={-1} />
              </Link>
              <Link isExternal href="https://www.linkedin.com/company/nestle-s-a-/">
                <Icon name="LinkedIn" size="4xs" h="auto" />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
