import { Flex, Image } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'

export const Hero = () => {
  return (
    <Flex w="full" direction="column" mb={10}>
      <Flex
        position="relative"
        h={{ base: '220px', md: '300px' }}
        bgImage={s3ImageURL('ricore-hero.png')}
        bgSize="cover"
        bgPosition="0 60%"
      >
        <Flex w="full" zIndex={2} direction="row" justifyContent="end" position="absolute">
          <Flex direction={'column'} my={8} mr={8} alignItems="center">
            <Image src={s3ImageURL('ricore-logo.png')} w={{ base: '200px', md: '300px' }} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
