import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'

export const CommentCaMarche = () => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      w="90%"
      border="solid 15px"
      borderColor="brand"
      p={{ base: 6, md: 12 }}
      bgColor="very_very_light_brand"
      mb={{ base: 12, md: 20 }}
    >
      <Flex direction={{ base: 'column', md: 'row' }} columnGap={16} rowGap={8}>
        <Flex direction="column" alignItems="flex-start">
          <Heading size="xl" mb={6}>
            Comment ça marche&nbsp;?
          </Heading>
          <Text color="brand_text" fontWeight="bold" mb={8} fontSize={21}>
            RICORÉ<sup style={{ fontSize: 14, verticalAlign: 'super' }}>®</sup> soutient 3 projets agricoles laitiers !
          </Text>
          <Text mb={8} fontSize={18}>
            RICORÉ® investit 20 000 € pour aider les producteurs laitiers de son usine à Challerange.
            <br />
            Chaque projet recevra 3 300 € garantis. Les 10 000 € restants seront répartis en fonction de vos votes en
            ligne.
          </Text>
          <Text fontWeight="bold" fontSize={18}>
            Votre voix peut faire la différence !
          </Text>
          <Text fontSize={18}>Découvrez les projets et votez pour votre préféré !</Text>
        </Flex>
        <Flex direction="column" w={{ base: 'full', md: '50%' }} alignItems="center" rowGap={2}>
          <Image src={s3ImageURL('ricore-carte.png')} alt={''} maxW="200px" p={{ base: 2, md: 4 }} />
          <Text fontWeight="bold" color="brand_text" fontSize={32} w="80%">
            20 000€ pour financer les projets laitiers des producteurs RICORÉ
            <sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
