import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import React from 'react'

export const RicoreSoutient = () => {
  return (
    <Flex direction="column" w="full" px={{ base: 2, md: 16 }} alignItems={'center'} mb={{ base: 12, md: 20 }}>
      <Box as="span" id="demarche" visibility="hidden" height="0" transform="translateY(-80px)" />
      <Heading mb={8} w={'100vw'} textAlign="center">
        RICORÉ<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup> soutient les producteurs laitiers
      </Heading>
      <Flex w="full" direction={{ base: 'column', md: 'row' }} columnGap={8} px={4} mb={8}>
        <Flex position="relative" w={{ base: 'full', md: '50%' }} boxShadow="dark-lg" mb={8}>
          <Image src={s3ImageURL('ricore-vaches.jpeg')} alt={''} objectFit="cover" w="100%" p={{ base: 2, md: 4 }} />
        </Flex>
        <Flex w={{ base: 'full', md: '50%' }} direction="column" justifyContent="center" m={{ base: 0, md: 6 }}>
          <Text mb={8}>
            <Text as={'span'} fontWeight="bold">
              RICORÉ® s’engage auprès des éleveurs laitiers
            </Text>{' '}
            fournisseurs de son usine à Challerange.
          </Text>
          <Text mb={8}>Vous pouvez les aider aussi !</Text>
          <Text>
            Votre avis compte pour nous, votez pour votre projet agricole préféré. Le projet qui reçoit le plus de votes
            peut gagner jusqu’à 13 300 € pour financer son initiative agricole.
          </Text>
        </Flex>
      </Flex>
      <Button as="a" variant="brandPrimary" href="#agriculteurs">
        Je vote pour mon projet préféré
      </Button>
    </Flex>
  )
}
