import { Flex, Heading } from '@chakra-ui/react'
import { questions } from './data/faqQuestions'
import { AccordionFAQ } from '@components/common'

export const FAQ = () => {
  return (
    <Flex
      position="relative"
      alignItems="center"
      direction="column"
      p={{ base: '30px 0', md: '30px' }}
      bgColor="very_light_brand"
      w="full"
    >
      <Heading textAlign="center" size="xl" mb={8}>
        FAQ
      </Heading>
      <AccordionFAQ border="solid 5px #FFDC00" borderRadius="0px" questions={questions} />
    </Flex>
  )
}
